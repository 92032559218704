import React from 'react'

const ReplacePos: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="56"
      height="57"
      viewBox="0 0 56 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4442_17375)">
        <path
          d="M33.25 10.9004H40.25C41.6424 10.9004 42.9777 11.4535 43.9623 12.4381C44.9469 13.4226 45.5 14.758 45.5 16.1504V17.9004"
          stroke="#00CC2C"
          strokeWidth="1.25"
          strokeMiterlimit="10"
        />
        <path
          d="M38.5 5.65039L33.25 10.9004L38.5 16.1504"
          stroke="#00CC2C"
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M22.75 45.9004H15.75C14.3576 45.9004 13.0223 45.3473 12.0377 44.3627C11.0531 43.3781 10.5 42.0428 10.5 40.6504V38.9004"
          stroke="#00CC2C"
          strokeWidth="1.25"
          strokeMiterlimit="10"
        />
        <path
          d="M17.5 51.1504L22.75 45.9004L17.5 40.6504"
          stroke="#00CC2C"
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <rect
          x="29.75"
          y="23.1504"
          width="21"
          height="31.5"
          rx="2.5"
          fill="#8CF9A4"
          stroke="#00CC2C"
          strokeWidth="1.25"
        />
        <path
          d="M46.375 33.0254C46.375 33.3706 46.0952 33.6504 45.75 33.6504H34.75C34.4048 33.6504 34.125 33.3706 34.125 33.0254V29.0254C34.125 28.6802 34.4048 28.4004 34.75 28.4004H45.75C46.0952 28.4004 46.375 28.6802 46.375 29.0254V33.0254Z"
          fill="white"
          stroke="#00CC2C"
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <ellipse cx="35" cy="38.9004" rx="1.75" ry="1.75" fill="#00CC2C" />
        <ellipse cx="35" cy="44.1504" rx="1.75" ry="1.75" fill="#00CC2C" />
        <ellipse cx="35" cy="49.4004" rx="1.75" ry="1.75" fill="#00CC2C" />
        <ellipse cx="40.25" cy="44.1504" rx="1.75" ry="1.75" fill="#00CC2C" />
        <ellipse cx="40.25" cy="38.9004" rx="1.75" ry="1.75" fill="#00CC2C" />
        <ellipse cx="40.25" cy="49.4004" rx="1.75" ry="1.75" fill="#00CC2C" />
        <ellipse cx="45.5" cy="38.9004" rx="1.75" ry="1.75" fill="#00CC2C" />
        <circle cx="45.5" cy="44.1504" r="1.75" fill="#00CC2C" />
        <ellipse cx="45.5" cy="49.4004" rx="1.75" ry="1.75" fill="#00CC2C" />
        <rect
          x="1.75"
          y="2.15039"
          width="21"
          height="31.5"
          rx="2.5"
          fill="#8CF9A4"
          stroke="#00CC2C"
          strokeWidth="1.25"
        />
        <path
          d="M18.375 12.0254C18.375 12.3706 18.0952 12.6504 17.75 12.6504H6.75C6.40482 12.6504 6.125 12.3706 6.125 12.0254V8.02539C6.125 7.68021 6.40482 7.40039 6.75 7.40039H17.75C18.0952 7.40039 18.375 7.68021 18.375 8.02539V12.0254Z"
          fill="white"
          stroke="#00CC2C"
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <ellipse cx="7" cy="17.9004" rx="1.75" ry="1.75" fill="#00CC2C" />
        <ellipse cx="7" cy="23.1504" rx="1.75" ry="1.75" fill="#00CC2C" />
        <ellipse cx="7" cy="28.4004" rx="1.75" ry="1.75" fill="#00CC2C" />
        <ellipse cx="12.25" cy="23.1504" rx="1.75" ry="1.75" fill="#00CC2C" />
        <ellipse cx="12.25" cy="17.9004" rx="1.75" ry="1.75" fill="#00CC2C" />
        <ellipse cx="12.25" cy="28.4004" rx="1.75" ry="1.75" fill="#00CC2C" />
        <ellipse cx="17.5" cy="17.9004" rx="1.75" ry="1.75" fill="#00CC2C" />
        <circle cx="17.5" cy="23.1504" r="1.75" fill="#00CC2C" />
        <ellipse cx="17.5" cy="28.4004" rx="1.75" ry="1.75" fill="#00CC2C" />
      </g>
      <defs>
        <clipPath id="clip0_4442_17375">
          <rect
            width="56"
            height="56"
            fill="white"
            transform="translate(0 0.400391)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ReplacePos
